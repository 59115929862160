<template>
  <div id="projects">
  


    <ProjectBlock v-for="project in projects"
    :code="project.projectCode"
    :name="project.name"
    :projecttags="project.tags"
    :overview="project.description"
    :contentBlocks="project.projectContent"
    :texts="project.projectTextblocks"
    :fullWidth="project.projectContent.fullWdith"
    :key="project.id"

    />


  </div>
</template>
<script>
import ProjectBlock from "@/components/ProjectBlock.vue"
import gql from "graphql-tag";

export default {  
  name: 'Projects',
  components: {
    ProjectBlock
  },    
  data() {
    return {
    	
    }
  },  
apollo: {
    projects: {
       query: gql`
      {
        projects(orderBy: order_DESC) {
          projectCode
          id
          name
          description {
          	html
          }
          tags(orderBy: name_ASC) {
          	name
          }
          projectContent {
            ... on ProjectSlider {
              image {
                url
                invert
              }
              fullWidth
            }
            ... on ProjectVideo {
              video {
                url
              }
            }
          }  
          projectTextblocks {
          	title
          	content
          }
        }
      }
    `,

    },    
  },
}
</script>