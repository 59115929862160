import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createHttpLink } from 'apollo-link-http';
import VueApollo from 'vue-apollo'
import {IntrospectionFragmentMatcher} from 'apollo-cache-inmemory';
import introspectionQueryResultData from '../src/fragmentTypes.json';
import './assets/tailwind.css'
import MarqueeText from 'vue-marquee-text-component'
import VueAnalytics from 'vue-analytics'

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
});

const cache = new InMemoryCache({ fragmentMatcher });

// API link here
const apolloClient = new ApolloClient({
	cache,
	link: createHttpLink({ uri: 'https://api-ap-northeast-1.graphcms.com/v2/ckhe5l0u15ik101zd24ox3wfi/master' }),
});

Vue.use(VueApollo)
Vue.config.productionTip = false

const apolloProvider = new VueApollo({
	defaultClient: apolloClient,
})


Vue.use(VueAnalytics, {
  id: 'G-N13D34LESF'
})

new Vue({
  router,
  apolloProvider,
  render: h => h(App)
}).$mount('#app')
