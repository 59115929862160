<template>
    <div class="fixed md:top-0 z-20 justify-between md:static flex flex-wrap w-full bg-black text-white ttu mono">
      <div class="w-full md:w-1/4 xl:w-1/4 flex justify-between">
        <div class="px-4 md:w-full">[{{ timestamp }}]</div>
        <span class="px-4 md:w-full block md:hidden"> <router-link class="logo bg-black "to="/">[ S I ]</router-link> 
        </span>
      </div>
      <div class="md:block md:w-3/4 text-right md:text-left">
        <div class="md:pl-4">
          <a v-if="siteOption.newsLink" :href="siteOption.newsLink" target="_blank">
            <marquee-text :duration="30" :repeat="4">
              {{siteOption.newsText}} [ {{siteOption.newsLink}} ] 
            </marquee-text>
          </a>
          <div v-else>
            <marquee-text :duration="30" :repeat="4">
              {{siteOption.newsText}}
            </marquee-text>
          </div>
        </div>
      </div>
    </div>	
</template>

<script>
import gql from "graphql-tag";
import MarqueeText from 'vue-marquee-text-component'

export default {
  name: 'Byline',
  components: {
    MarqueeText
  },
  data() {
    return {
    	timestamp: "",
      siteOption:{
        newsText: null,
        newsLink: null
      }
    }
  },
  created() {
  	setInterval(this.getNow, 1000);
  },
  methods: {
  	getNow: function() {
    const today = new Date();``
    const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    const dateTime = date +' '+ time;
    this.timestamp = time;  		
  	}
  },
apollo: {
    siteOption: {
       query: gql`
      {
        siteOption(where: {id: "ckhe670h407t70b87smkuas3b"}) {
          newsText
          newsLink
        }
      }
    `,

    },    
  },  
}
</script>


<style scoped lang="scss">
.logo.router-link-active {
  background-color: black !important;
  color:white !important; 
}

</style>

<style>
  .marquee-text-text {
    margin-right: 1rem;
  }
  .marquee-text-wrap:hover{
    background-color: white;
    color:black;
  }
</style>

