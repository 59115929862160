<template>
    <div id="studio">
        <div class="flex flex-wrap justify-end md:px-6">
            <div class="fs2 mb-6 w-full md:w-3/4">
                <div class="p-2 bg-black text-white p-rules" v-html="studio.description.html"></div>
            </div>
            <div class="mb-6 w-full md:w-1/3" v-for="studioTextBlock in studio.studioTextBlocks">
                    <div class="bg-white text-black fs1 p-2">
                        <span class="mono block uppercase">
                            {{studioTextBlock.title}}
                        </span>
                        <div v-html="studioTextBlock.content.html" class="whitespace-pre-line p-rules"></div>
                    </div>
            </div>
        </div>
    </div>
</template>
<script>
import gql from "graphql-tag";

export default {  
  name: 'Studio',
  data() {
    return {
    	  studio: {
          description: {html:null}
        },
        studioTextBlocks: {
         title: null,
          content: {html:null}
        }
    }
  },
apollo: {
    studio: {
       query: gql`
      {
        studio(where: {id: "ckhe71zg008880a39pzpht0z6"}) {
          description {
            html
          }
          studioTextBlocks {
            title
            content {
              html
            }
          }
      }
      }
    `,

    },    
  },
}
</script>