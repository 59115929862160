<template>
    <div class="footer text-white mono uppercase">
        <div class="w-full flex flex-wrap justify-end md:px-6">       
            <div class="w-full md:w-1/4 md:pr-6">
            	<a class="text-black bg-white md:text-white md:bg-black block p-2" target="_blank" :href="'https://instagram.com/' + siteOption.instagram">Instagram</a>
            </div>
            <div class="w-full md:w-1/4 md:pr-6">
            	<a class="text-black bg-white md:text-white md:bg-black block p-2" :href="'mailto:' + siteOption.email">{{siteOption.email}}</a>
            </div>
            <div class="w-full md:w-1/4">
            <a class="text-black bg-white md:text-white md:bg-black block p-2" href="http://u-p.co">[ S I ] Website: U-P</a>           	
            </div>                        
        </div>
    </div>
</template>

<script>
import gql from "graphql-tag";

export default {
  name: 'Footer',
  data() {
  	return {
      siteOption: {
        instagram: null
      }
  	}
  },
  apollo: {
    siteOption: {
       query: gql`
       {
  siteOption(where: {id: "ckhe670h407t70b87smkuas3b"}) {
    instagram
    name
    email
  	}       	
       }
    `,

    },    
  },    
}
</script>


<style scoped lang="scss">


</style>
