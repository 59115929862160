<template>
  <div class="app bg-gray h-screen text-white sans">
    <Byline />
    <Nav />
    <Meta />
    <router-view/>
    <Footer />
  </div>
</template>

<script>
  import Meta from '@/components/Meta.vue'
  import Footer from '@/components/Footer.vue'
  import Nav from '@/components/Nav.vue'
  import Byline from '@/components/Byline.vue'

  export default {
  name: 'App',
  components: {
    Meta,
    Nav,
    Byline,
    Footer
  }
}

</script>

<style lang="scss">

html {
  height: 100%; 
  background-color: #b5b5b5;
}

.app {
  min-height: 100vh;
}

.router-link-active {
  background-color:#b5b5b5 !important;
  color:black !important; 
}

.sans {
  // font-family: "Monument", arial;
  font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
  letter-spacing: -0.02em;
  font-weight: bold;
}

.mono {
  font-family: "RecollectionMono", times;
  font-weight: normal;
  letter-spacing: 0.06em;
}

// @font-face {
//   font-family:MonumentMono;
//   src:  url('./fonts/MonumentGrotesk-Mono.woff2') format('woff2'),
//         url('./fonts/MonumentGrotesk-Mono.woff') format('woff');
// }

@font-face {
  font-family:RecollectionMono;
  src:  url('./fonts/RecollectionMono-Regular.woff2') format('woff2');
  src:  url('./fonts/RecollectionMono-Regular.woff') format('woff');
}

@font-face {
  font-family:Monument;
  src:  url('./fonts/MonumentGrotesk-Bold.woff2') format('woff2'),
        url('./fonts/MonumentGrotesk-Bold.woff') format('woff');
}

p {
    margin-block-start: 0em;
    margin-block-end: 0em;
}

::selection {
  color: black;
  background: #b5b5b5;
}

</style>
