<template>
  <div class="home">
    <div class="fs2 ttu tar bg-black text-white ml-auto w-3/4 p-2 pb-2 md:hidden">Spatial Information</div>
  	<div class="flex flex-wrap justify-end md:px-6">
  		<div class="w-full md:w-3/4 ml-auto bg-black text-white p-2 mb-6">
  			<div class="fs1 mono uppercase">{{siteOption.homepageTextTitle}}</div>
  			<div class="fs1 md:fs2">
  				{{siteOption.homepageText}}
  			</div>
  		</div>
  	</div>
  	<div class="w-full">
  		<video class="w-full" :src="siteOption.homepageVideo.url" preload="auto" muted autoplay loop playsinline></video>
  	</div>
  </div>
</template>

<script>
import gql from "graphql-tag";


export default {
  name: 'Home',
  data() {
    return {
      siteOption: {
        homepageVideo: {
        	url: null
        },
        homepageText: null,
        homepageTextTitle : null
      }
    }
  },
  apollo: {
    siteOption: {
       query: gql`
       {
  siteOption(where: {id: "ckhe670h407t70b87smkuas3b"}) {
    homepageText
    homepageTextTitle
    homepageVideo {
      url
    }
  }       	
       }
    `,

    },    
  },  
}
</script>
