<template>
    <div>
        <!-- small nav -->
        <div class="fixed nav md:top-0 z-10 w-full mt-6 md:mt-0 md:px-6" v-if="scrollpx > 200">
            <div class="nav flex flex-wrap w-full sans">
                <div class="hidden md:block w-1/2 xl:w-1/4">
                    <router-link class="db pt-8 px-2 pt-2 w-full fs2 fwb ttu bg-white text-black no-underline cursor-pointer" to="/" exact>Spatial Information</router-link>
                </div>
                <div class="w-1/2 md:w-1/4">
                    <router-link class="db pt-8 px-2 pt-2 w-full fs2 fwb ttu bg-white text-black no-underline cursor-pointer" to="/projects">Projects</router-link>
                </div>
                <div class="w-1/2 md:w-1/4 xl:w-1/2">
                    <router-link class="db pt-8 px-4 pt-2 w-full fs2 fwb ttu bg-white text-black no-underline cursor-pointer" to="/studio">Studio</router-link>
                </div>
            </div>
        </div>
        <!-- desktop nav -->
        <div class="nav md:flex-row flex flex-wrap w-full sans md:px-6">
            <div class="w-full md:w-1/2 xl:w-1/4">
                <router-link class="hidden md:block px-2 pt-20 w-full fs2 fwb ttu bg-white text-black no-underline cursor-pointer" to="/" exact>Spatial Information</router-link>
            </div>
            <div class="w-1/2 md:w-1/4">
                <router-link class="db px-2 pt-20 w-full fs2 fwb ttu bg-white text-black no-underline cursor-pointer" to="/projects">Projects</router-link>
            </div>
            <div class="w-1/2 md:w-1/4 xl:w-1/2">
                <router-link class="db px-2 pt-20 w-full fs2 fwb ttu bg-white text-black no-underline cursor-pointer" to="/studio">Studio</router-link>
            </div>
        </div>  
    </div>
</template>

<script>
export default {
  name: 'Nav',
  data() {
    return {
        scrollpx: 0
    }
  },
  methods: {
    handleScroll() {
        this.scrollpx = window.scrollY;
    }
  },
    created() {
      window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
      window.removeEventListener('scroll', this.handleScroll);
    }
}
</script>


<style scoped lang="scss">

</style>
