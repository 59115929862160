<template>
    <div class="meta hidden md:flex flex-wrap w-full ttu fs1 mono text-gray-400 md:px-6">
      <div class="w-1/2 bg-white md:pr-6">
        <div v-if="siteOption.aboutHeader" class="px-2 py-2">
          {{siteOption.aboutHeader}}
        </div>
      </div>
      <div class="w-1/2 bg-black md:pr-6">
        <div class="px-4 py-2">
          
        </div>
      </div>      
    </div>  	
</template>

<script>
import gql from "graphql-tag";

export default {
  name: 'Meta',
  data() {
    return {
      siteOption: {
        address:null,
        aboutHeader: null
      }
    }
  },
  apollo: {
    siteOption: {
       query: gql`
      {
        siteOption(where: {id: "ckhe670h407t70b87smkuas3b"}) {
          address
          aboutHeader
        }
      }
    `,

    },    
  },
}
</script>


<style scoped lang="scss">

</style>
