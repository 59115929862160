<template>
    <div class="mt-12 md:mt-0 mb-12">
        
        <div class="codetag-block flex flex-wrap md:px-6">

            <div class="w-1/2 fs2 text-black">
                <div class="p-2">{{code}}</div>
            </div>

            <div class="flex flex-wrap w-full md:w-1/2 py-2">
                <template v-for="tag in tags">
                    <span class="ml-6 pl-1 md:mr-4 dot relative  text-black mono uppercase" :class="{'dot active': projecttags.map(({name}) => name).includes(tag.name)}">
                        {{tag.name}}
                    </span>
                </template>
            </div>

        </div>

        <div class="flex flex-wrap md:px-6">
          <template v-for="contentBlock in contentBlocks">
            <div v-if="contentBlock.video" class="video-block flex items-end video pt-6 w-full lg:w-1/2 bg-black overflow-hidden">
              <video class="w-full" playsinline muted autoplay loop preload="auto">
                <source :src="contentBlock.video.url" type="video/mp4">
              </video>
            </div>
            <template v-if="contentBlock.image" class="">
              <div :class="{ 'w-full-i' : contentBlock.fullWidth === true}"  class="slider pt-6 w-full lg:w-1/2 bg-black overflow-hidden">
                <Slider :image-array="contentBlock.image" />
              </div>
            </template>

          </template>
        </div>

        <div class="flex flex-wrap w-full md:px-6">
            <div class="title w-full md:w-1/2 fs2 ">
                <div class="text-black bg-white p-2">{{name}}</div>
            </div>
            <div class="title w-full md:w-1/2 fs2 text-black p-4 md:p-8 bg-white">
                <div class="relative">
                    <span :class="[isActive ? 'active' : '']" class="cross cursor-pointer" @click="isActive = !isActive"></span>
                    <span class="block pl-4 w-1/2 ml-auto md:w-full md:pl-16 cursor-pointer" @click="isActive = !isActive">Information</span>
                </div>
            </div>
        </div>

        <div class="flex flex-wrap text-black items-start justify-end md:px-6" :class="[isActive ? 'block' : 'hidden']">
            <div class="flex flex-wrap w-full lg:w-1/2 justify-end">
                <template v-for="text in texts">
                    <div class="w-full md:w-1/2 md:mb-6 md:pr-6">
                        <div class="bg-white text-black fs1 p-2 whitespace-pre-line">
                            <span class="mono ttu block">{{text.title}}</span>
                            {{text.content}}
                        </div>
                    </div>
                </template>
            </div>
            <div v-if="overview" class="fs2 w-full md:w-1/2">
                <div class="bg-white p-2 p-rules" v-html="overview.html"></div>
            </div>
        </div>
    </div>
</template>
<script>
import gql from "graphql-tag";
import Slider from '@/components/Slider.vue'

export default {
    name: 'ProjectBlock',
    components: {
      Slider
    },
    props: {
        code: String,
        name: String,
        invert: Boolean,
        projecttags: Array,
        overview: Object,
        texts: Array,
        contentBlocks: Array,
    },
    data() {
        return {
            isActive: false,
            categories: []
        }
    },
    apollo: {
        tags: {
            query: gql `
        {
          tags(orderBy: name_ASC) {
            name
          }
        }
    `,
        },
    },
}
</script>

<style lang="scss">
.w-full-i {
    width: 100% !important;
}
.slider img {
  height: auto;
  width: auto;
  max-width:100%;
  max-height: 100%;
}

.slider.invert img {
      filter: invert(1) grayscale(1);
}

.slider.invert:hover img {
    filter: invert(0) grayscale(0);
}

</style>

<style scoped lang="scss">
  .dot{
    &:before {
    content:' ';
    width:15px;
    height:15px;
    top:4px;
    left:-1rem;
    display: inline-block;
    border:1px solid #000;
    position: absolute;
    }
    &.active:before {
    background-color:#000;
    }
  }
.slider, .video-block {
  max-height: 90vh;
}

.slider div{
  height: 100%;
  position: relative;
}
.cross{
  width: 60px;
  height: 60px;
  position: absolute;
  left:-20px;
  top:-20px;
  &.active{
      &:before {transform: rotate(45deg);}      
  }
  &.active{
      &:after {transform: rotate(-45deg);}      
  }
  &:before{
      transform: rotate(0deg);
  }
  &:after{
      transform: rotate(90deg);
  }  
  &:before, &:after {
      position: absolute;
      left: 30px;
      content: ' ';
      height: 60px;
      width: 1px;
      background-color: #000;
  }
}
  </style>}
