<template>
    <div>
        <VueSlickCarousel ref="carousel" :accessibility="false">
            <div class="h-full" v-for="(image, indx) in imageArray" :key="indx" :index="indx">
                <div :class="{'cursor-pointer': imageArray.length > 1}" @click="showNext" class="align flex h-full ">
                    <img :class="{ 'invert' : image.invert === true}" v-if="image.url" class="self-end align-bottom" :src="image.url">
                </div>
            </div>
            <template #prevArrow="arrowOption">
                <div class="custom-arrow to text-white mono">
                    [ {{ arrowOption.currentSlide + 1 }}/{{ arrowOption.slideCount }} ]
                    <!-- <span class="sans cursor-pointer"@click="showNext">Next</span> -->
                </div>
            </template>
        </VueSlickCarousel>
    </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'


export default {
  name: 'ImageSlider',
  components: {
    VueSlickCarousel
  },
  props: ["image-array"],
  data () {
    return {
      carouselData: 0
    }
  },
  methods: {
      showNext() {
        this.$refs.carousel.next()
      }
  }       
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.slick-slider {height:100%;}
.slick-track {display: flex !important;outline: none !important; height:100%;}
.slick-slide{height: inherit !important;outline: none !important;}
.slick-slide > div { height: 100%;outline: none !important; }
.slick-slide, .slick-slide *{ outline: none !important; }
.slick-list {line-height: 0; height: 100%}
.slick-next {display: none !important;}
.custom-arrow {
  position: absolute;
  top:-25px;
  left:5px;
}
img.invert {
      filter: invert(1) grayscale(1);
}

img.invert:hover {
    filter: invert(0) grayscale(0);
}
</style>
